/* eslint-disable react/prop-types */
import * as React from "react";
import Navbar from "../components/navbar";
import Img from "gatsby-image";
import { graphql, Script, navigate } from "gatsby";
import * as style from "./index.module.css";
import * as biostyle from "../components/bio.module.css";
import "./project-spotlight.css";
import "animate.css";

// define constants
const NAME = "leo martinez";
const EMAIL = "bleo@mit.edu";

const ProjectSpotlight = ({ data }) => {
  const [projectName, setProjectName] = React.useState("");

  /**
   * Change the project name during mount or if the data changes
   */
  React.useEffect(() => {
    setProjectName(data.allFile.nodes[0].relativeDirectory.toUpperCase());
  }, [data]);

  const images = data.allFile.nodes.map((node) => ({
    ...node.childImageSharp, // Note that we're spreading the childImageSharp object here
    id: node.id,
    directory: node.relativeDirectory,
    filename: node.relativePath,
  }));

  return (
    <div className={`${style.viewport} ${style.gallery_scroll}`}>
      <Script
        src="https://kit.fontawesome.com/cde1a27a61.js"
        crossorigin="anonymous"
      ></Script>
      <Navbar name={NAME} email={EMAIL}></Navbar>
      <i
        className="fas fa-arrow-left fa-2x"
        onClick={() => {
          navigate("/projects");
        }}
      />
      <div
        className={`${biostyle.big_bold_text} animate__animated animate__fadeIn`}
      >
        {projectName}
      </div>
      <div className="carousel">
        {images.map(({ id, directory, filename, fixed }) => (
          <Img key={filename} fixed={fixed} className="image" fadeIn />
        ))}
      </div>
    </div>
  );
};

export const query = graphql`
  query projectSpotlight($directory: String) {
    allFile(
      filter: {
        sourceInstanceName: { eq: "gallery" }
        relativeDirectory: { eq: $directory }
      }
    ) {
      nodes {
        id
        relativeDirectory
        relativePath
        childImageSharp {
          fixed(height: 800) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`;

export default ProjectSpotlight;
